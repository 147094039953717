import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import VueDomPurifyHTML from 'vue-dompurify-html'
import mitt from 'mitt'
//import CKEditor from '@ckeditor/ckeditor5-vue'

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.emitter = emitter
//store.commit('setGlobalToken', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im9ob2hvQGtha2FvLmNvbSIsImV4dGVuZCI6IjE5MjYyMDI0MDkiLCJjaHVyY2hJZCI6MSwiZGl2aXNpb25JZCI6MywidXNlcklkIjo5LCJpYXQiOjE2OTM4ODA2ODQsImV4cCI6MTY5OTA2NDY4NH0.sloFJKH_3mOkbzbzS3S0omnZlSEZ-RR9Uu7NodW87L8');
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${store.state.globalToken}`;
    config.baseURL = 'https://heyapi.heychurch.net'; //'http://localhost:3000'; //'http://heyapi.heychurch.net';

    return config;
});

//app.use( CKEditor ).mount();

window.app = app
    .use(router)
    .use(store)
    .use(VueDomPurifyHTML)
    .mount("#app");
