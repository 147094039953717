import { createStore } from "vuex";

export default createStore({
    state: {
        globalToken: '',
        smallgroup: {
            newSmallgroup: {
                title: '',
                profileUrl: '',
            },
            lastMenuIndex: 0,
            userInfo: null,
            id: 0,
        },
        uiCallback: null,
        userInfo: null,        
        signup: {
            user: null,
            church: null,
            mainDivision: null,
            subDivision: [],
        },
        tempChurch: {
            name: '',
            seniorPastor: '',
            denominationName: '',
            denominationId: 0,
            independentChurch: '',
            nationNumber: '82',
            phone: '',
            address: '',
            detailAddress: '',
            postalCode: '',
        },
        tempSelectedAuth: {
            authority: null,
            division: null,
        }
    },
    getters: {
        smallgroupNewData(smallgroup) {
            return smallgroup.newSmallgroup;
        },
        getSmallgroupLastMenuIndex(state) {
            return state.smallgroup.lastMenuIndex;
        },
        getUserInfo(state) {
            return state.smallgroup.userInfo;
        },
        getSmallgroupUserInfo(state) {
            return state.smallgroupUserInfo;
        },
        getGlobalToken(state) {
            return state.globalToken;
        },
        getSmallgroupId(state) {
            return state.smallgroup.id;
        },
        getLastSelectedAuthority(state) {
            return state.tempSelectedAuth;
        }
    },
    mutations: {
        setSmallgroupNewData(smallgroup) {
            smallgroup.newSmallgroup.title = smallgroup.title;
            smallgroup.newSmallgroup.profileUrl = smallgroup.profileUrl;
        },
        setSmallgroupLastMenuIndex(state, index) {
            state.smallgroup.lastMenuIndex = index;
        },
        setGlobalToken(state, token) {
            state.globalToken = token;
        },
        setUserInfo(state, userinfo) {
            state.userInfo = userinfo;
        },
        setSmallgroupUserInfo(state, userinfo) {
            state.smallgroup.userInfo = userinfo;
        },
        setSmallgroupId(state, id) {
            state.smallgroup.id = +id;
        },
        setLastSelectedAuthority(state, {authority, division}) {
            state.tempSelectedAuth.authority = authority;
            state.tempSelectedAuth.division = division;
        }
    }
})