<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  created() {
    /*let scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', '/js/connect.js');
    document.head.appendChild(scriptElement);*/

    /*scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', '/js/tinymce/tinymce.min.js');
    document.head.appendChild(scriptElement);*/
  },
  setup() {
    const otherWayCall = (param) => {
      alert(param);
    }

    return {
      otherWayCall,
    }
  }
}
</script>

<style>
@import './assets/css/variables.css';

#app {
  font-family: 'Pretendard' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  overflow: hidden;
  color: #3a4a41;
  height: 100%;
  box-sizing: border-box;
}

html, body {
  margin: 0px;
  height: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
</style>
