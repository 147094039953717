import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: '/share',
        component: () => import('../pages/share'),
    },
    {
        path: '/texteditor',
        component: () => import('../pages/editor/TextEditor.vue'),
    },
    {
        path: '/noticeeditor',
        component: () => import('../pages/editor/NoticeEditor.vue'),
    },
    {
        path: '/createStep1',
        name: 'createStep1',
        component: () => import("../pages/smallgroup/create/pageStep1.vue"),
    },
    {
        path: '/createStep2',
        name: 'createStep2',
        component: () => import("../pages/smallgroup/create/pageStep2.vue"),
    },
    {
        path: '/list',
        component: () => import("../pages/smallgroup/list"),
        props: true,
    },
    {
        path: '/main',
        name: 'main',
        component: () => import("../pages/smallgroup/main"),
    },
    {
        path: '/introWrite',
        component: () => import("../pages/smallgroup/main/pages/introWritePage.vue"),
    },
    {
        path: '/talkWrite',
        component: () => import("../pages/smallgroup/main/pages/talkWritePage.vue"),
    },
    {
        path: '/talkModify',
        component: () => import("../pages/smallgroup/main/pages/talkModifyPage.vue"),
    },
    {
        path: '/authConfig',
        component: () => import("../pages/smallgroup/main/pages/authConfigPage.vue"),
    },
    {
        path: '/changeMaster',
        component: () => import("../pages/smallgroup/main/pages/changeMasterPage.vue"),
    },
    {
        path: '/changeSubmaster',
        component: () => import("../pages/smallgroup/main/pages/changeSubmasterPage.vue"),
    },
    {
        path: '/setupConfig',
        component: () => import("../pages/smallgroup/main/pages/setupConfigPage.vue"),
    },
    {
        path: '/changeProfile',
        component: () => import("../pages/smallgroup/main/pages/changeProfilePage.vue"),
    },
    {
        path: '/memberAdd',
        component: () => import("../pages/smallgroup/main/pages/memberAddPage.vue"),
    },
    {
        path: '/memberBye',
        component: () => import("../pages/smallgroup/main/pages/memberByePage.vue"),
    },
    {
        path: '/randomCard',
        component: () => import("../pages/smallgroup/main/pages/randomCardPage.vue"),
    },
    {
        path: '/myFaith',
        component: () => import("../pages/mypage/pages/myFaithPage.vue"),
    },
    {
        path: '/managerConfig',
        component: () => import("../pages/mypage/pages/managerConfigPage.vue"),
    },
    {
        path: '/faithHistory',
        component: () => import("../pages/mypage/pages/faithHistoryPage.vue"),
    },
    {
        path: '/alarmHistory',
        component: () => import("../pages/mypage/pages/alarmHistoryPage.vue"),
    },
    {
        path: '/alarmConfig',
        component: () => import("../pages/mypage/pages/alarmConfigPage.vue"),
    },
    {
        path: '/moreConfig',
        component: () => import("../pages/mypage/pages/moreConfigPage.vue"),
    },
    {
        path: '/msgList',
        component: () => import("../pages/mypage/pages/msgListPage.vue"),
    },
    {
        path: '/makeCheckform',
        component: () => import("../pages/mypage/pages/makeCheckformPage.vue"),
    },
    {
        path: '/resultCheckform',
        component: () => import("../pages/mypage/pages/resultCheckformPage.vue"),
    },
    {
        path: '/dropdone',
        component: () => import("../pages/mypage/pages/dropDonePage.vue"),
    },
    {
        path: '/heycompany',
        component: () => import("../pages/mypage/pages/heycompanyPage.vue"),
    },
    {
        path: '/churchLogin',
        component: () => import("../pages/mypage/pages/churchLoginPage.vue"),
    },
    {
        path: '/chat',
        component: () => import("../pages/chat/chatPage.vue"),
    },
    {
        path: '/chatTarget',
        component: () => import("../pages/chat/targetPage.vue"),
    },
    {
        path: '/categoryEdit',
        component: () => import("../pages/smallgroup/main/pages/categoryEditPage.vue"),
    },
    {
        path: '/community',
        children: [
            {
                path: '/community/list',
                component: () => import('../pages/community/list')
            },
            {
                path: '/community/create',
                component: () => import('../pages/community/create')
            },
            {
                path: '/community/edit',
                component: () => import("../pages/community/edit"),
            },
        ]
    },
    {
        path: '/mypage',
        children: [
            {
                path: '/mypage/authManage',
                component: () => import('../pages/auth/pages/authManagePage.vue')
            },
            {
                path: '/mypage/authHelp',
                component: () => import('../pages/auth/pages/authHelpPage.vue')
            },
            {
                path: '/mypage/authMemberAdd',
                component: () => import('../pages/auth/pages/authMemberAddPage.vue')
            },
            {
                path: '/mypage/authMemberBye',
                component: () => import('../pages/auth/pages/authMemberByePage.vue')
            },
            {
                path: '/mypage/approve',
                component: () => import('../pages/mypage/pages/approvePage.vue')
            },
            {
                path: '/mypage/memberList',
                component: () => import('../pages/mypage/pages/memberListPage.vue')
            },
            {
                path: '/mypage/memberBye',
                component: () => import('../pages/mypage/pages/memberByePage.vue')
            },
            {
                path: '/mypage/onelineqt',
                component: () => import('../pages/qt')
            }
        ]
    },
    {
        path: '/tipoff',
        children: [
            {
                path: '/tipoff/communityReview',
                component: () => import('../pages/tipoff/pages/communityReviewPage.vue')
            },
            {
                path: '/tipoff/replyReview',
                component: () => import('../pages/tipoff/pages/replyReviewPage.vue')
            },
        ]
    },
    {
        path: '/signup',
        children: [
            {
                path: '/signup/step1',
                component: () => import('../pages/signin/pages/agreeTermsPage.vue')
            },
            {
                path: '/signup/step2',
                component: () => import('../pages/signin/pages/joinTypePage.vue')
            },
            {
                path: '/signup/step3',
                component: () => import('../pages/signin/pages/selectChurchPage.vue')
            },
            {
                path: '/signup/admin1',
                component: () => import('../pages/signin/pages/kakao_admin/admin1Page.vue')
            },
            {
                path: '/signup/admin2',
                component: () => import('../pages/signin/pages/kakao_admin/admin2Page.vue')
            },
            {
                path: '/signup/admin3',
                component: () => import('../pages/signin/pages/kakao_admin/admin3Page.vue')
            },
            {
                path: '/signup/admin9',
                component: () => import('../pages/signin/pages/kakao_admin/admin9Page.vue')
            },
            {
                path: '/signup/member1',
                component: () => import('../pages/signin/pages/kakao_member/step31Page.vue')
            },
            {
                path: '/signup/member9',
                component: () => import('../pages/signin/pages/kakao_member/step39Page.vue')
            },
            {
                path: '/signup/acceptWait',
                component: () => import('../pages/signin/pages/acceptWaitPage.vue')
            },
            {
                path: '/signup/reject',
                component: () => import('../pages/signin/pages/rejectPage.vue')
            },
            {
                path: '/signup/findChurch',
                component: () => import('../pages/signin/pages/findChurchPage.vue')
            },
            {
                path: '/signup/findAddress',
                component: () => import('../pages/signin/pages/findAddressPage.vue')
            },
            {
                path: '/signup/findDenomination',
                component: () => import('../pages/signin/pages/findDenominationPage.vue')
            },
        ]
    }
]

let router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
